
































import { Component, Vue } from 'vue-property-decorator';
import Tracks from './components/Tracks.vue';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['filterOptions', 'showLights']),
  components: {
    Tracks
  }
})
export default class App extends Vue {
  // from mapstate
  public filterOptions!: string[];
  public showLights!: boolean;
  // ----------------
  public showFilterOptions: boolean = false;
  public toggleOption(option: string){
    this.$store.commit('toggleOption', option)
  }
  public toggleLights(){
    this.$store.commit('toggleLights')
  }
  public showFilter(){
    this.showFilterOptions = true;
  }
  public closeFilter(){
    this.showFilterOptions = false;
  }
  public isSelected(option: string){
    const so: string[] = this.$store.state.selectedOptions;
  return so.indexOf(option) >= 0 ? 'selected' : '';
  }
}
