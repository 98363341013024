import Vue from 'vue';
import Vuex from 'vuex'
import State from "./types/state";
import axios, { AxiosResponse } from 'axios';
Vue.use(Vuex);

export default new Vuex.Store({
    state: new State(),
    mutations: {        
        setTracks(state, data){
            // console.log(data);
            if(data !== ''){
                state.tracks = data.tracks;
                state.allTracks = data.tracks;
                state.filterOptions = data.filterOptions;
            } else {
                state.tracks = [];
            }           

        },
        toggleOption(state, option: string){
            // console.log(option)
            if (state.selectedOptions && state.selectedOptions.length > 0 && state.selectedOptions.indexOf(option) >= 0 ) {
                state.selectedOptions.splice(state.selectedOptions.indexOf(option), 1);
            } else {
                state.selectedOptions.push(option);
            }
            localStorage.setItem('tracks', JSON.stringify(state.selectedOptions));
            state.tracks = state.selectedOptions.length > 0 ? state.allTracks.filter(t => state.selectedOptions.includes(t.filterName)): state.allTracks;  
        },
        filterTracks(state){
            console.log(state.selectedOptions.length);
            state.tracks = state.selectedOptions.length > 0 ? state.allTracks.filter(t => state.selectedOptions.includes(t.filterName)): state.allTracks;  
        },
        toggleLights(state){            
            state.tracks = state.selectedOptions.length > 0 ? state.allTracks.filter(t => state.selectedOptions.includes(t.filterName)): state.allTracks;  
            state.showLights = !state.showLights;
            if (state.showLights) {                
                state.tracks = state.tracks.filter(track => track.lights === true)
            } else {
                
            }
        }
    },
    actions: {
        loadTracks({commit}){
            axios
            .get(process.env.VUE_APP_SERVER + '/api/tracks/')
            .then((payload: AxiosResponse<any>) => {                
                commit('setTracks', payload.data);
                commit('filterTracks');
                 
            });
        }
    }
})