






















import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import Track from "../types/track";

@Component({
    computed: mapState(["tracks"])
})
export default class Tracks extends Vue {
    @Prop() private msg!: string;
    // Mapped from store
    public tracks!: Track[];
    // -----------------
    public formatDate(date: Date) : string {
        //console.log(date);
      let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        let hours = d.getHours().toString();
        let minutes = d.getMinutes().toString();
    //console.log(d)
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
        
    if (hours.length < 2) 
        hours = '0' + hours;
    if (minutes.length < 2) 
        minutes = '0' + minutes;
        return hours + ':' + minutes + " " + day + "/" + month;
    }
    mounted() {
        this.$store.dispatch('loadTracks');
              
    }
}
