import axios, { AxiosResponse } from 'axios';
import Track from './track';
export default class State {
  public allTracks: Track[];
  public tracks: Track[];
  public selectedOptions: string[];
  public filterOptions: string[];
  public showLights: boolean;


  public constructor() {
    this.allTracks = [];
    this.tracks = [];
    this.selectedOptions = JSON.parse(localStorage.getItem('tracks')) || [];
    this.filterOptions = [];
    this.showLights = false;
  }

}
